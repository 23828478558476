'use client'

import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import { FallbackProps } from 'react-error-boundary'
import { ThemeProvider, createTheme } from '@mui/material'
import { RcButton } from '@/components/molecules/interactive/RcButton'

export const ApiOffline = ({ error }: FallbackProps) => {
  return (
    <ThemeProvider
      theme={createTheme({
        palette: { mode: 'dark' },
        components: {
          MuiBackdrop: {
            styleOverrides: { root: { backgroundColor: 'black' } }
          }
        }
      })}
    >
      <Dialog open>
        <Alert
          severity='error'
          variant='outlined'
          action={
            <RcButton onClick={() => window.location.reload()}>
              Try Again
            </RcButton>
          }
        >
          Server is unreachable or something else unexpected went wrong. You can
          try again and/or report this issue to us.{' '}
          <pre style={{ whiteSpace: 'pre-wrap', fontSize: '.7rem' }}>
            {error.message}
          </pre>
        </Alert>
      </Dialog>
    </ThemeProvider>
  )
}
