'use client'

import { borderAnimation } from '@/core/animations'
import Box from "@mui/material/Box";
import Button from '@mui/material/Button'
import { ButtonProps } from '@mui/material/Button'
import React, { forwardRef, useState } from 'react'

export const RcButton = forwardRef(function RcButton(
  props: ButtonProps & { component?: React.ElementType; highlight?: boolean },
  ref: React.Ref<HTMLButtonElement>
) {
  const [disabled, setDisabled] = useState(false)

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setDisabled(true)
    try {
      await props.onClick?.(event)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <BorderAnimator highlight={props.highlight && !disabled}>
      <Button
        {...props}
        ref={ref}
        onClick={handleClick}
        disabled={props.disabled || disabled}
      />
    </BorderAnimator>
  )
})

export const BorderAnimator = ({
  children,
  highlight
}: {
  children: React.ReactNode
  highlight?: boolean
}) => {
  if (!highlight) return children
  return (
    <Box position={'relative'} p={0.5}>
      <Box
        sx={theme => ({
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          p: 0.5,
          borderRadius: 15,
          ...borderAnimation()
        })}
      />
      {children}
    </Box>
  )
}
