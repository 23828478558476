'use client'

import { ApiOffline } from '@/components/pages/Site/ApiOffline'

export default function Error({
  error,
  reset
}: {
  error: Error
  reset: () => void
}) {
  return <ApiOffline error={error} resetErrorBoundary={reset} />
}
